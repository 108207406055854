<template>
  <div class="login">
    <h2>Kirjaudu sisään</h2>
    <form @submit.prevent="login">
      <input v-model="username" type="text" placeholder="Käyttäjänimi" required>
      <!-- Add a checkbox for staying logged in -->
      <div>
        <input v-model="stayLoggedIn" type="checkbox" id="stayLoggedIn">
        <label for="stayLoggedIn">Pysy kirjautuneena sisään</label>
      </div>
      <button type="submit">Kirjaudu</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      stayLoggedIn: false, // Add a data property to track the checkbox status
    };
  },
  methods: {
  login() {
    const roles = {
      'Marko.K': 'pomo',
      'Mikko.H': 'pomo',
      'Eetu.M': 'pomo',
      'Tommi.S': 'pomo',
      'Marko.N': 'kuljettaja',
      'Pete.L': 'pomo',
      'Riina.H': 'pomo', 
      'Tuomo.T': 'pomo',
      'Fernando.I': 'pomo',
      'Lauri.L': 'pomo',
      'Taneli.H': 'pomo',

    };

    const routeNames = {
      'pomo': 'pomo',
      'kuljettaja': 'kuljettaja',
      'tekija': 'homeT', // Mapping 'tekija' role to 'homeT' route name
    };

    const userRole = roles[this.username];
    if (userRole) {
      localStorage.setItem('username', this.username); // Store username
      localStorage.setItem('userRole', userRole); // Store role
      if (this.stayLoggedIn) {
        localStorage.setItem('stayLoggedIn', 'true');
      } else {
        localStorage.removeItem('stayLoggedIn');
      }

      // Navigate to the correct route based on the user's role
      const routeName = routeNames[userRole] || 'Login'; // Fallback to 'Login' if no match
      this.$router.push({ name: routeName });
    } else {
      alert('Käyttäjää ei löydy');
    }
  },
},

};
</script>

  
  <style scoped>
  /* Modern styling */
  .home {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .order-form {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  textarea,
  select {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-top: 5px;
    box-sizing: border-box;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .order-list {
    padding: 0;
    list-style: none;
  }
  
  .order-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .home {
      max-width: 800px;
      margin: 0 auto;
    }
  
    .order-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  
    .form-group {
      margin-bottom: 0;
    }
  
    .form-group:last-child {
      grid-column: span 2;
    }
  }
  </style>
  